<!-- @Author: Yu_Bo -->
<template>
    <div class='video_preview zc_dialog_box'>
        <el-dialog :visible.sync="dialogVisible" width="690px" :close-on-click-modal='false' @close='cancelBtn'>
            <div slot="title">
                <div class="name">预览</div>
            </div>
            <div class="video_main">
                <div class="main_con">
                    <video ref="videoPlayer" :src="info.video_url" controls autoplay loop="loop" controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        info: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            dialogVisible: false,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialogBtn() {
            this.dialogVisible = true
        },
        // 取消
        cancelBtn() {
            this.dialogVisible = false
            this.$refs.videoPlayer.pause();//暂停
            // this.$refs.videoPlayer.play();//播放
        },
    },
}
</script>

<style lang='scss' scoped>
.video_preview {
    .video_main {
        width: 100%;

        .main_con {
            width: 300px;
            height: 470px;
            margin: auto;

            video {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>
