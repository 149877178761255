<template>
    <div class="classify_add_eidt">
        <el-dialog class="zc_dialog_box" :visible.sync="dialogVisible" width="500px" :close-on-click-modal='false' @close="cancelBtn">
            <div slot="title">
                <div class="name">{{ title }}</div>
            </div>
            <div class="main_info">
                <div class="info_text">
                    <div class="t_left"><span>*</span>视频名称</div>
                    <div class="t_right">
                        <el-input v-model="info.name" maxlength="10" :clearable="true" placeholder="请输入视频名称"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left"><span>*</span>分类</div>
                    <div class="t_right">
                        <el-select v-model="info.category_id" :clearable="true" placeholder="请选择分类">
                            <el-option v-for="item in list" :key="item.value" :label="item.label" :value="numFuntion(item.value)">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left"><span>*</span>缩略图</div>
                    <div class="t_right" style="width: 150px;">
                        <file-upload :width="100" :height="100" type="img_1" @upload="successUpload1">
                            <div class="picture_img_upload">
                                <div class="img_yes" v-if="info.thumbnail">
                                    <el-image :src="info.thumbnail" fit="scale-down"></el-image>
                                    <div class='succ_btn'>
                                        <i class="el-icon-picture-outline-round" @click.stop="playImg(info.thumbnail)"></i>
                                        <i class="el-icon-circle-plus-outline"></i>
                                    </div>
                                </div>
                                <div class="img_no" v-else>
                                    <i class="el-icon-plus"></i>
                                    <div>上传图片</div>
                                </div>
                            </div>
                        </file-upload>
                    </div>
                    <div class="t_left" style="width: 72px;"><span>*</span>视频</div>
                    <div class="t_right" style="width: 140px;">
                        <file-upload :width="100" :height="100" type="video_1" @upload="successUpload2">
                            <div class="picture_img_upload">
                                <div class="img_yes" v-if="info.url">
                                    <video class="video" :src="info.url"></video>
                                    <div class='succ_btn'>
                                        <i class="el-icon-video-play" @click.stop="playVideo(info.url)"></i>
                                        <i class="el-icon-circle-plus-outline"></i>
                                    </div>
                                </div>
                                <div class="img_no" v-else>
                                    <i class="el-icon-plus"></i>
                                    <div>上传视频</div>
                                </div>
                            </div>
                        </file-upload>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left">视频时长(秒)</div>
                    <div class="t_right">
                        <el-input v-model="info.duration" disabled :clearable="true" placeholder="视频时长"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left"><span>*</span>状态</div>
                    <div class="t_right">
                        <el-switch v-model="info.status" :active-value="1" :inactive-value="2"></el-switch>
                    </div>
                </div>
            </div>
            <div slot="footer">
                <el-button class="btnBgColor_grey" size="small" @click="cancelBtn">取 消</el-button>
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 预览图片 -->
        <img-preview ref="img"></img-preview>
        <!-- 预览视频 -->
        <video-preview ref="video"></video-preview>
    </div>
</template>

<script>
let that
import FileUpload from '@/components/file_upload/index.vue'// 上传
import ImgPreview from '@/components/img_preview/index.vue'
import VideoPreview from '@/components/video_preview/video.vue'
export default {
    components: { FileUpload, ImgPreview, VideoPreview },
    props: {
        list: {
            type: Array,
            defaul: []
        }
    },
    data() {
        return {
            title: '',
            dialogVisible: false,
            // 
            info: {
                name: '',
                category_id: '',
                thumbnail: '',
                url: '',
                duration: '',
                status: 2,
            },
            // 
            subLoading: false
        };
    },

    computed: {},

    watch: {},

    created() {
        that = this
    },

    mounted() { },

    methods: {
        // 打开
        openDialog(info = '') {
            if (info) {
                that.title = '编辑视频'
                that.info = info
            } else {
                that.title = '添加视频'
                that.info = {
                    name: '',
                    category_id: '',
                    thumbnail: '',
                    duration: '',
                    url: '',
                    status: 2,
                }
            }
            that.dialogVisible = true
        },
        // 取消
        cancelBtn() {
            that.info = {
                name: '',
                category_id: '',
                thumbnail: '',
                duration: '',
                url: '',
                status: 2,
            }
            that.dialogVisible = false
        },
        // 确定
        affirmBtn() {
            var obj = JSON.parse(JSON.stringify(that.info))
            if (obj.name == '') {
                that.$warMsg('请输入图片名称')
            } else if (obj.category_id == '') {
                that.$warMsg('请选择分类')
            } else if (obj.thumbnail == '') {
                that.$warMsg('请上传缩略图')
            } else if (obj.url == '') {
                that.$warMsg('请上传视频')
            } else if (obj.id) {
                // 编辑
                that.subLoading = true
                var params = {
                    ...obj
                }
                that.$materialApi.videoListEdit(params, obj.id).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('affirm')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else {
                // 添加
                that.subLoading = true
                var params = {
                    ...obj
                }
                that.$materialApi.videoListAdd(params).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.cancelBtn()
                        that.$emit('affirm')
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 缩略图
        successUpload1(item) {
            that.info.thumbnail = item.long_url
        },
        // 展示图
        successUpload2(item) {
            that.info.url = item.long_url
            that.info.duration = item.duration
        },
        // 转换数字类型
        numFuntion(id) {
            return id * 1
        },
        // 预览图片
        playImg(url){
            this.$refs.img.openDialog(url)
        },
        playVideo(url){
            this.$refs.video.openDialog(url)
        },
    }
}
</script>
<style lang='scss' scoped>
.classify_add_eidt {
    .main_info {
        width: 100%;
        .info_text {
            width: 100%;
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .t_left {
                // width: 80px;
                padding-right: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                text-align: right;
                span {
                    color: #e51d41;
                }
            }
            .t_right {
                position: relative;
                width: 355px;
                .el-input {
                    width: 100%;
                }

                .el-select {
                    width: 100%;
                }
                ::v-deep .el-textarea {
                    width: 100%;
                    .el-textarea__inner {
                        padding-bottom: 20px;
                    }
                }
                .input_btn {
                    position: absolute;
                    bottom: 5px;
                    left: 10px;
                    padding: 0px;
                    background: #fff;
                }
            }
        }
        .info_text:last-child {
            padding-bottom: 0;
        }
    }
}
.succ_btn{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border-radius: 5px;
    background: rgba($color: #000000, $alpha: 0.5);
    display: none;
    align-items: center;
    justify-content: space-around;
    i{
        font-size: 26px;
        color: #fff;
    }
}
.img_yes:hover .succ_btn{
    display: flex;
}
</style>