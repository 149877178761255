<template>
    <div class='video_preview'>
        <div class="image_box_main" v-if="dialogVisible">
            <div class="img_close">
                <div class="close app_region_no" @click="cancelBtn"><i class="el-icon-close"></i></div>
            </div>
            <div class="video_main">
                <div class="main_con">
                    <video ref="videoPlayer" :src="videoUrl" controls="controls" autoplay loop="loop"
                        controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            videoUrl: '',
            dialogVisible: false,
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开弹框
        openDialog(url) {
            this.videoUrl = url
            this.dialogVisible = true
            // 将组件挂在body上
            this.$nextTick(() => {
                const body = document.querySelector("body");
                if (body.append) {
                    body.append(this.$el);
                } else {
                    body.appendChild(this.$el);
                }
            });
        },
        // 取消
        cancelBtn() {
            // 将组件从body上销毁
            const body = document.querySelector("body");
            body.removeChild(this.$el);

            this.dialogVisible = false
            this.$refs.videoPlayer.pause(); //暂停
            // this.$refs.videoPlayer.play();//播放
        },
    },
}
</script>

<style lang='scss' scoped>
.video_preview {
    width: 100%;

    .image_box_main {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000000;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);

        .video_main {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .main_con {
                width: 400px;
                height: 712px;
                margin: auto;

                video {
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: #000;
                }
            }
        }

        .img_close {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            /* max-width: 30%; */
            display: flex;
            justify-content: flex-end;

            .close {
                cursor: pointer;
                width: 40px;
                height: 40px;
                font-size: 24px;
                color: #fff;
                background: rgba(0, 0, 0, .5);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0 0 0 40px;

                .el-icon-close {
                    font-size: 20px;
                    margin-left: 5px;
                    margin-top: -5px;
                }
            }
        }
    }
}
</style>
